import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import {Container} from 'react-bootstrap';
import ResourcesIntroduction from "../../components/resourcesIntroduction"
import MarkdownContent from "../../components/markdownContent"
import Cta from "../../components/cta"
import Favicon from "../../images/favicon.png"

const Download = ({ data, ...props }) => {
  const download = data.strapiDownloads
  return (
    <Layout>
      <Container className="page resources single download">
        <Seo
          title={download.seo !== null ? download.seo.meta_title !== null ? download.seo.meta_title : 'Geen meta titel' : ''} 
          image={{src: download.seo !== null ? download.seo.meta_image !== null ? download.seo.meta_image.image.localFile.publicURL : '' : '', height: 628, width: 1200}}
          description={download.seo !== null ? download.seo.meta_description !== null ? download.seo.meta_description : '' : ''} 
          pathname={props.location.pathname}
          favicon={Favicon}
        />
        <ResourcesIntroduction
          title={download.title}
          subtitle="Downloads"
          classes="single-resource_intro"
          padding={{ bottom:80, top:80,left:15,right:15 }}
          resource={download}
          name="Downloads"
        />
        <MarkdownContent 
          content={download.content}
        />
        <Cta
          verticalStyling={false}
          title=""
          intro="Doelstellingen vertalen naar design, development en marketing."
          link="/discovery"
          linkText="Ontdek de mogelijkheden"
          titleSize="h2"
          className="resources-cta"
        />
      </Container>
    </Layout>
  )
}


export const query = graphql`
  query DownloadQuery($slug: String!) {
    strapiDownloads(slug: {eq: $slug}) {
      strapiId
      content
      title
      created_at
      updated_at(formatString: "DD MMMM YYYY", locale: "nl")
      published_at(formatString: "DD MMMM YYYY", locale: "nl")
      slug
      intro_text
      featured_image {
        alternativeText
        localFile {
          absolutePath
          publicURL
          childImageSharp {
            gatsbyImageData(placeholder: DOMINANT_COLOR, formats: WEBP, width: 1110)
          }
        }
        updated_at
      }
      labels {
        id
        slug
        name
        color
      }
      downloads_categories {
        id
        name
        published_at(formatString: "DD MMMM YYYY", locale: "nl")
        slug
      }
      seo {
        id
        indexable
        meta_description
        meta_keywords
        meta_title
        meta_image {
          meta_image_alt
          image {
            localFile {
              absolutePath
              publicURL
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`

export default Download